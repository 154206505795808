import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type {
  HeatMapCountryMetaData,
  HeatMapCountryStatuses,
} from '../../../../lcpn-uploader/server/services/parse-map-records/heat-map/heat-map-types';
import { Icon } from '../../Icon';

import type { Heading, Section } from './types';

const getStageProperties = (status: Omit<HeatMapCountryStatuses, 'all'>) => {
  switch (status) {
    case 'Unknown status': {
      return {
        color: 'white',
        backgroundColor: 'rgba(222, 112, 107)',
        stage: 1,
        translationString: 'countryRow.statusBar.unknownStatus',
      };
    }

    case 'Implementation research': {
      return {
        color: 'white',
        backgroundColor: 'rgba(158, 18, 66)',
        stage: 2,
        translationString: 'countryRow.statusBar.implementationResearch',
      };
    }

    case 'Formal commitment': {
      return {
        color: 'white',
        backgroundColor: 'rgba(75, 171, 214)',
        stage: 3,
        translationString: 'countryRow.statusBar.formalCommitment',
      };
    }

    case 'Implementation roll-out': {
      return {
        color: 'white',
        backgroundColor: 'rgba(244, 180, 50)',
        stage: 4,
        translationString: 'countryRow.statusBar.implementationRollOut',
      };
    }

    case 'Regional roll-out': {
      return {
        color: 'white',
        backgroundColor: 'rgba(222, 112, 107)',
        stage: 5,
        translationString: 'countryRow.statusBar.regionalRollOut',
      };
    }

    case 'National roll-out': {
      return {
        color: 'white',
        backgroundColor: 'rgba(244, 180, 50)',
        stage: 6,
        translationString: 'countryRow.statusBar.nationalRollOut',
      };
    }
  }
};

export function useHeatMapDataTableDefinitions() {
  const { t } = useTranslation();
  const sections: Section[] = [
    {
      title: t('countryRow.definitionTables.participantOutcomesLDCT.title'),
      backgroundColor: '#DE706B',
      rows: [
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesLDCT.previousTrials',
          ),
          key: 'previousLcdtScreening',
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesLDCT.pilots',
          ),
          key: 'pilotsLcdtScreening',
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesLDCT.organisedProgrammes',
          ),
          key: 'hasOrganisedLdctProgramme',
        },
      ],
    },
    {
      title: t('countryRow.definitionTables.participantOutcomesNCCP.title'),
      backgroundColor: '#DE706B',
      rows: [
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesNCCP.availability',
          ),
          key: 'nccpAvailable',
          fallback: '—',
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesNCCP.lastPublished',
          ),
          key: 'nccpLastUpdated',
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesNCCP.earlyDetection',
          ),
          key: 'earlyDetectionInNccp',
        },
        {
          title: t('countryRow.definitionTables.participantOutcomesNCCP.PBCR'),
          key: 'pbcr',
          fallback: '—',
        },
      ],
    },
    {
      title: t(
        'countryRow.definitionTables.participantOutcomesIncidence.title',
      ),
      backgroundColor: '#155181',
      rows: [
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesIncidence.bothSexes',
          ),
          key: 'incidenceCombined',
          format: v => v ?? '—',
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesIncidence.male',
          ),
          key: 'incidenceMale',
          format: v => v ?? '—',
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesIncidence.female',
          ),
          key: 'incidenceFemale',
          format: v => v ?? '—',
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesIncidence.globocanYear',
          ),
          key: 'globocanYear',
          format: v => v ?? '2020',
        },
      ],

      notes: [
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesIncidence.footnotesTitle',
          ),
          body: t(
            'countryRow.definitionTables.participantOutcomesIncidence.footnotesBody',
          ),
        },
      ],
    },
    {
      title: t(
        'countryRow.definitionTables.participantOutcomesMortality.title',
      ),
      backgroundColor: '#155181',
      rows: [
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesMortality.bothSexes',
          ),
          key: 'mortalityCombined',
          format: v => v ?? '—',
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesMortality.male',
          ),
          key: 'mortalityMale',
          format: v => v ?? '—',
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesMortality.female',
          ),
          key: 'mortalityFemale',
          format: v => v ?? '—',
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesMortality.globocanYear',
          ),
          key: 'globocanYear',
          format: v => v ?? '2020',
        },
      ],

      notes: [
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesMortality.footnotesTitle',
          ),
          body: t(
            'countryRow.definitionTables.participantOutcomesMortality.footnotesBody',
          ),
        },
      ],
    },
    {
      title: t(
        'countryRow.definitionTables.participantOutcomesAdditional.title',
      ),
      backgroundColor: '#333740',
      rows: [
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesAdditional.lastUpdated',
          ),
          key: 'updated',
          format: v => (v ?? '').toString(),
        },
        {
          title: t(
            'countryRow.definitionTables.participantOutcomesAdditional.footnotesTitle',
          ),
          key: 'references',
          typographyVariant: 'p3',
        },
      ],
    },
  ];

  const headings: Heading<HeatMapCountryMetaData>[] = [
    {
      backgroundColor: '#008B7F',
      color: 'white',
      typographyVariant: 'h3',
      key: item => (
        <>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Icon width={32} height={32} variant={'MapInverted'} />
            <div>{item.countryName}</div>
          </Stack>
        </>
      ),
    },
    {
      backgroundColor: 'rgba(0, 139, 127, 0.6)',
      color: 'white',
      mergeCells: true,
      typographyVariant: 'h4',
      key: () => <>{t('comparisonTable.heatMap.stageText')}</>,
    },
    {
      backgroundColor: item =>
        getStageProperties(item.countryStatus)!.backgroundColor,
      color: item => getStageProperties(item.countryStatus)!.color,
      typographyVariant: 'p1',
      key: item => {
        const stageProperties = getStageProperties(item.countryStatus);
        if (!stageProperties) return <></>;
        return (
          <>
            <div>
              {t('comparisonTable.heatMap.stageNumber', {
                replace: { stage: stageProperties.stage },
              })}
              <br />
              {t(stageProperties.translationString)}
            </div>
          </>
        );
      },
    },
    {
      typographyVariant: 'p1',
      key: 'countryStatusFootnote',
    },
  ];

  return { headings, sections };
}
